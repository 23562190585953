import labayhAPI from "../api/labayhAPI";
import { useEffect, useState } from "react";

const useCompanyDetails = () => {
  const [planType, setPlanType] = useState("");
  const [isLimitedAppointments, setIsLimitedAppointments] = useState(false);
  const [supportsFamilyMembers, setSupportsFamilyMembers] = useState(false);
  const [hotlineMaxCount,setHotlineMaxCount]=useState()
  const [supportsSurvey, setSupportsSurvey] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchCompanyDetails = async () => {
    try {
      setLoading(true);
      setError(false);
      const response = await labayhAPI.get("/api/company-admin/get-data");
      await localStorage.setItem("cId", response.data.foundCopmany._id);
      const planTypeData = response?.data.foundCopmany.planId.type;
      const isLimitedData = response?.data.foundCopmany.planId.isLimited;
      const hotlineMaxCount = response?.data?.foundCopmany.planId.hotlineMaxCount
      const supportsSurveyData =
        response?.data?.foundCopmany?.planId?.supportsSurvey;
      const supportsFamilyMembersData =
        response?.data?.foundCopmany?.planId?.supportsFamilyMembers;
      setSupportsFamilyMembers(supportsFamilyMembersData);
      setSupportsSurvey(supportsSurveyData);
      setIsLimitedAppointments(isLimitedData);
      setHotlineMaxCount(hotlineMaxCount)
      setPlanType(planTypeData);
      setLoading(false);
      setIsComplete(true);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCompanyDetails();
  }, []);

  return {
    planType,
    isLimitedAppointments,
    error,
    loading,
    supportsSurvey,
    supportsFamilyMembers,
    isComplete,
    hotlineMaxCount
  };
};

export default useCompanyDetails;
