import { useMemo } from "react";
import { useEffect } from "react";
import { createContext, useContext, useState } from "react";
import { useStateLocal } from "src/hooks/useLocalState";

const DEFAULT_CONTEXT = {
  theme: "light",
  isDark: false,
  toggle: (v) => {},
  colors: {
    backgroundColor: "",
    backgroundColorSecondary: "",
    color: "#000",
  },
};

const DARK_MODE_1 = {
  backgroundColor: "#0F172A",
  backgroundColorSecondary: "#1E293B",
};
const DARK_MODE_2 = {
  backgroundColor: "#121113",
  backgroundColorSecondary: "#1B1C1F",
};

const COLORS = {
  dark: {
    ...DARK_MODE_2,
    color: "white",
  },
  light: {
    backgroundColor: "#F2F6FF",
    backgroundColorSecondary: "#fff",
    color: "#000",
  },
  green: {
    backgroundColor: "#037739",
    backgroundColorSecondary: "#E6FAFB",
    color: "#fff",
  },
};
const ThemeContext = createContext(DEFAULT_CONTEXT);
export function ThemeProvider({ children }) {
  const [theme, setTheme] = useStateLocal("light", "THEME");

  useEffect(() => {
    const html = document.querySelector("html");
    if (theme === "dark") html.classList.add("dark");
    else html.classList.remove("dark");
  }, [theme]);
  const colors = useMemo(() => COLORS[theme], [COLORS, theme]);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        toggle: () => setTheme(theme === "dark" ? "light" : "dark"),
        colors,
        isDark: theme === "dark",
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
}
export const useTheme = () => useContext(ThemeContext);
