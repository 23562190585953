import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

// Create context
const ThemeContext = createContext();

export const CustomerThemeProvider = ({ children }) => {
  const { pathname } = useLocation();
  const isPIF = pathname.split("/")[1].split("-")[0] === "pif";
  const [theme, setTheme] = useState("light");

  // Load theme preference from localStorage on initial render
  useEffect(() => {
    const savedTheme = localStorage.getItem("theme") || "light";
    setTheme(savedTheme);
    document.documentElement.className = savedTheme; // Apply theme class to root
  }, []);

  // Function to change theme
  const changeTheme = (newTheme) => {
    const html = document.querySelector("html");
    
    setTheme(newTheme);
    html.setAttribute("data-theme", newTheme);
    localStorage.setItem("theme", newTheme); // Persist theme to localStorage
  };

  useEffect(() => {
    if (isPIF) {
      changeTheme("light");
    } else {
      changeTheme("light");
    }
  }, [isPIF]);

  return (
    <ThemeContext.Provider value={{ theme, changeTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

// Custom hook for easy access to theme context
export const useTheme = () => useContext(ThemeContext);
